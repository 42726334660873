let listeners = []
let loadedListeners = []

export default async function init(params) {
    const {container} = params

    loadedListeners.forEach(listener => {
        initListener(listener, params)
    })

    listeners.forEach(async ({elements, load}, i) => {
        const currentElements = elements(container)
        if(!currentElements.length) {
            return
        }

        const listener = await load()
        loadedListeners.push(listener)
        delete listeners[i]
        initListener(listener, params)
    })
}

function initListener(listener, params) {
    listener.default(params)
}

export function teardown(params) {
    loadedListeners.forEach(({teardown}) => {
        if(teardown) {
            teardown(params)
        }
    })
}

export function addListener({name, elements, load}) {
    listeners.push({name, elements, load})
}
