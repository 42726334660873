import {findAll, on} from "@/utils/dom"
import {show, hide} from "@/utils/css"

/**
 * @params {HTMLElement} container
 */
export default function init({ container }) {
    const checkAllContainers = findAll('.check-all-container', container)
    if (!checkAllContainers.length) return

    checkAllContainers.forEach((checkAllContainer) => {
        const checkItems = checkAllContainer.querySelectorAll('.check-item')
        const checkAlls = checkAllContainer.querySelectorAll('.check-all')
        const formDeleteMultiple = checkAllContainer.querySelectorAll('.form-delete-multiple')

        checkAlls.forEach(checkAll => {
            checkAll.addEventListener('change', () => {
                checkItems.forEach(item => item.checked = checkAll.checked)
                updateCheckAllState()
            })
        })

        checkItems.forEach(item => item.addEventListener('change', updateCheckAllState))
        updateCheckAllState()

        formDeleteMultiple.forEach((form) => {
            on(form, 'submit', () => {
                const checkedItems = getCheckedItems()
                const ids = Array.from(checkedItems)
                    .map(item => item.value)
                form.querySelector('input[name="ids"]').value = ids.join(',')
            })
        })

        function getCheckedItems() {return Array.from(checkItems).filter(item => item.checked)}

        function updateCheckAllState() {
            const checkedItems = getCheckedItems()
            const hasCheckedItem = checkedItems.length > 0
            const hasAllCheckedItems = hasCheckedItem && checkedItems.length === checkItems.length
            checkAlls.forEach(checkAll => checkAll.checked = hasAllCheckedItems)

            formDeleteMultiple.forEach((form) => {
                if(hasCheckedItem) {
                    show(form)
                } else {
                    hide(form)
                }
            })
        }
    })
}
