export async function domReady()  {
    return new Promise((resolve) => {
        if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", () => resolve())
        } else {
            resolve()
        }
    })
}

export function activateScriptElement(element) {
    if (element.getAttribute("data-turbo-eval") === "false") {
        return element
    } else {
        const createdScriptElement = document.createElement("script")
        const cspNonce = getCspNonce()
        if (cspNonce) {
            createdScriptElement.nonce = cspNonce
        }
        createdScriptElement.textContent = element.textContent
        createdScriptElement.async = false
        copyElementAttributes(createdScriptElement, element)
        return createdScriptElement
    }
}

function getMetaElement(name) {
    return document.querySelector(`meta[name="${name}"]`)
}

function getCspNonce() {
    const element = getMetaElement("csp-nonce")

    if (element) {
        const { nonce, content } = element
        return nonce === "" ? content : nonce
    }
}

function copyElementAttributes(destinationElement, sourceElement) {
    for (const { name, value } of sourceElement.attributes) {
        destinationElement.setAttribute(name, value)
    }
}

export const dom = (selector) => findOne(selector, document)
export const findOne = (selector, element) => (element || document).querySelector(selector)
export const findAll = (selector, element) => (element || document).querySelectorAll(selector)

export const data = (element, name, value) => {
    // Getter
    if (typeof value === 'undefined') {
        return element.dataset[name]
    }

    element.dataset[name] = value
}

export const on = (element, event, handler, useCapture) => {
    event.split(' ').forEach((event) => {
        element.addEventListener(event, handler, useCapture)
    })
}
export const trigger = (element, eventName, params) => {
    const event = new CustomEvent(eventName, {
        cancelable: true,
        detail: params,
    })
    element.dispatchEvent(event)
    return event
}

export const remove = (element) => {
    element.parentNode.removeChild(element)
}

export const appendHTML = (element, html) => {
    const child = document.createElement('div')
    child.innerHTML = html

    while (child.firstChild) {
        element.appendChild(child.firstChild)
    }
}
