import ToastManager from '@/services/modals/ToastManager'
import ModalManager from '@/services/modals/ModalManager'
import CollectionManager from '@/services/form/CollectionManager'
import ElementManager from '@/services/form/ElementManager'
import {trans} from "@/translator"
import VisibleManager from "@/services/form/VisibleManager"
import DisabledManager from "@/services/form/DisabledManager"
import RequiredManager from "@/services/form/RequiredManager"
import EmptyManager from "@/services/form/EmptyManager"
import FormManager from "@/services/form/FormManager"
import HttpManager from "@/services/http/HttpManager"
import ErrorRenderer from "@/services/ui/ErrorRenderer"
import FormFeedbackRenderer from "@/services/ui/FormErrorManager"

/**
 * @param {Container} di
 * @param {App} app
 */
export default (di, app) => {
    const appRef = new WeakRef(app)
    di.set('app', () => appRef.deref())
    di.set('translator', () => {
        return {
            trans
        }
    })

    di.set('httpManager', () => new HttpManager())
    di.set('errorRenderer', () => new ErrorRenderer())
    di.set('formFeedbackRenderer', () => new FormFeedbackRenderer())

    di.set('autocompleteManager', async () => {
        const {default: AutocompleteManager} = await import('@/services/ui/AutocompleteManager')
        return new AutocompleteManager()
    })

    di.set('dateManager', async () => {
        const {default: DateManager} = await import('@/services/ui/DateManager')
        return new DateManager()
    })

    di.set('modalManager', () => new ModalManager(di))

    di.set('elementManager', () => new ElementManager())

    di.set('toastManager', () => new ToastManager())

    di.set('visibleManager', () => {
        return new VisibleManager()
    })

    di.set('disabledManager', () => {
        return new DisabledManager()
    })

    di.set('requiredManager', () => {
        return new RequiredManager()
    })

    di.set('emptyManager', () => {
        return new EmptyManager()
    })

    di.set('formManager', () => {
        return new FormManager(
            di.get('visibleManager'),
            di.get('disabledManager'),
            di.get('requiredManager'),
            di.get('emptyManager')
        )
    })

    di.set('collectionManager', () => new CollectionManager(
        di.get('modalManager'),
        di.get('app'),
    ))
}
