export default class HttpResponse {
    #success
    #statusCode
    #statusText
    #data
    #error

    /**
     * @param {Boolean} success
     * @param {int} statusCode
     * @param {String} statusText
     * @param {Object|undefined} data
     * @param {string} error
     */
    constructor({success, statusCode, statusText, data, error}) {
        this.#success = success
        this.#statusCode = statusCode
        this.#statusText = statusText
        this.#data = data
        this.#error = error
    }

    /**
     * @returns {int}
     */
    get statusCode() {
        return this.#statusCode
    }

    /**
     * @returns {String}
     */
    get statusText() {
        return this.#statusText
    }

    /**
     * @returns {Boolean}
     */
    get success() {
        return this.#success
    }

    /**
     * @returns {Object|undefined}
     */
    get data() {
        return this.#data
    }

    /**
     * @returns {string}
     */
    get error() {
        return this.#error
    }
}
