import {findAll, on} from "@/utils/dom"

/**
 * @params {App} app
 */
export default async function init({ container }) {
    const inputs = findAll('input.form-selectgroup-input', container)

    if (!inputs.length) {
        return
    }

    inputs.forEach((input) => {
        on(input, 'change', () => {
            if(!input.checked) {
                input.blur()
            }
        })
    })
}
