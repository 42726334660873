import {findAll} from "@/utils/dom"

/**
 * @params {App} app
 * @params {HTMLElement} container
 */
export default async function init({ container, app }) {
    const addresspickerInputs = findAll('.addresspicker-input', container)
    if (!addresspickerInputs.length) return

    /** @var {AutocompleteManager} autocompleteManager */
    const autocompleteManager = await app.get('autocompleteManager')
    const routes = app.get('routes')
    addresspickerInputs.forEach((addresspickerInput) => {
        const addresspicker = addresspickerInput.closest('.addresspicker')
        const postalCode = addresspicker.querySelector('.addresspicker-postalcode')
        const city = addresspicker.querySelector('.addresspicker-city')
        const country = addresspicker.querySelector('.addresspicker-country')
        const latitude = addresspicker.querySelector('.addresspicker-latitude')
        const longitude = addresspicker.querySelector('.addresspicker-longitude')

        autocompleteManager.handle({
            selector: addresspickerInput,
            url: routes.api.address,
            labelKey: 'label',
            valueKey: 'address',
            onSelected(value) {
                postalCode.value = value.postalCode
                city.value = value.city
                country.value = value.country
                country.tomselect.sync()
                latitude.value = value.latitude
                longitude.value = value.longitude
            }
        })
    })
}
