import {StreamActions} from "@hotwired/turbo"

/**
 * @param {App} app
 */
export default function init({app}) {
    StreamActions.alert = function () {
        const type = this.getAttribute("type")
        const title = this.getAttribute("title")
        const description = this.getAttribute("description")

        /** @var {ModalManager} modalManager */
        const modalManager = app.get('modalManager')

        modalManager.createModal({
            icon: type,
            title,
            html: description,
        })
    }
}
