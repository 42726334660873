import {findAll, on} from "@/utils/dom"

/**
 * @params {App} app
 * @params {HTMLElement} container
 */
export default function init({app, container}) {
    const switchUsers = findAll('.switch-user', container)
    if (switchUsers.length === 0) {
        return
    }

    switchUsers.forEach((switchUser) => {
        on(switchUser, 'click',  async (e) => {
            e.preventDefault()
            const modalManager = app.get('modalManager')
            const email = await modalManager
                .createPrompt({
                    title: 'Email',
                    input: 'email',
                    allowEscapeKey: true,
                    allowOutsideClick: true,
                    showCloseButton: true,
                })

            if (email) {
                window.location = switchUser.getAttribute('href').replace('__identifier__', email)
            }
        })
    })
}
