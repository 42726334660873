import {findAll, findOne, on} from "@/utils/dom"

/**
 * @params {HTMLElement} container
 */
export default function init({ container }) {
    const forms = findAll('.form-filter', container)
    if (!forms.length) return

    forms.forEach((form) => {
        on(form, 'submit', () => {
            findAll('.form-filter-item', form).forEach((formFilterItem) => {
                const filterElement = findOne(`#${formFilterItem.dataset.filterId}`)
                formFilterItem.value = filterElement?.value ?? ''
            })
        })
    })
}
