import { Controller } from '@hotwired/stimulus'

const imports = {
    'pages/register': () => import('@/pages/register'),
    'pages/member/information': () => import('@/pages/member/information'),
    'pages/member/profile': () => import('@/pages/member/profile'),
    'pages/member/matching-criteria': () => import('@/pages/member/matching-criteria'),
    'pages/member/meeting': () => import('@/pages/member/meeting'),
    'admin/user-profile': () => import('@/pages/admin/user-profile'),
}

/* stimulusFetch: 'eager' */
export default class extends Controller {
    static values = {
        pages: Array,
        params: Object,
    }

    connect() {
        this.pagesValue.forEach(async (pageId) => {
            await imports[pageId]()
            window.app.loadPage(pageId, this.paramsValue)
        })
    }

    disconnect() {
        this.pagesValue.forEach((pageId) => window.app.unloadPage(pageId, this.element, true))
    }
}
