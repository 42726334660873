import $ from 'jquery'
import HttpResponse from "@/services/http/HttpResponse"

export default class HttpManager {
    /**
     * @param {String} url
     * @param {FormData|Object|undefined} data
     * @param {Object} options
     * @returns {Promise<HttpResponse>}
     */
    async post(url, data = undefined, options = {throwOnError: false}) {
        const jqueryOptions = {
            method: 'POST',
            data,
        }

        if(data instanceof FormData) {
            jqueryOptions.contentType = false
            jqueryOptions.processData = false
        }

        return this.#makeRequest(url, jqueryOptions, options)
    }

    #constructSuccessResponse(data, textStatus, jqXHR) {
        return new HttpResponse({
            success: true,
            data,
            ... this.#parseJqueryResponse(jqXHR, textStatus)
        })
    }

    #constructErrorResponse(options, jqXHR, textStatus, errorThrown) {
        return new HttpResponse({
            success: false,
            data: jqXHR.responseJSON,
            error: errorThrown,
            ... this.#parseJqueryResponse(jqXHR, textStatus)
        })
    }

    #makeRequest(url, options) {
        return new Promise((resolve, reject) => {
            $.ajax(url, options)
                .done((data, textStatus, jqXHR) => resolve(this.#constructSuccessResponse(data, textStatus, jqXHR)))
                .fail((jqXHR, textStatus, errorThrown) => {
                    const response = this.#constructErrorResponse(options, jqXHR, textStatus, errorThrown)
                    const {throwOnError} = options
                    if(throwOnError) {
                        reject(response)
                    } else {
                        resolve(response)
                    }
                })
        })
    }

    #parseJqueryResponse(jqXHR, textStatus) {
        return {
            statusCode: jqXHR.status,
            statusText: textStatus,
        }
    }
}
