import {dom, findAll, on} from "@/utils/dom"

/**
 * @params {HTMLElement} container
 */
export default function init({ container }) {
    const tableSorts = findAll('.table-sort', container)
    if (!tableSorts.length) return

    tableSorts.forEach((tableSort) => {
        on(tableSort, 'click', () => {
            const sortField = dom(`#${tableSort.dataset.sortFieldId}`)
            const sortDirection = dom(`#${tableSort.dataset.directionFieldId}`)
            const form = sortField.closest('form')

            sortField.value = tableSort.dataset.sort
            sortDirection.value = tableSort.dataset.direction === 'ASC' ? 'DESC' : 'ASC'
            form.requestSubmit()
        })
    })
}
