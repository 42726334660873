import ElementManager from './ElementManager'
import { constructArrayDefinition } from '@/utils/utils'
import {on} from "@/utils/dom"

export default class EmptyManager {
    constructor() {
        this.elementManager = new ElementManager()
    }

    handle(emptyDefinition, reverseDefinition) {
        for (const [elementId, definition] of Object.entries(emptyDefinition)) {
            const element = this.elementManager.getElement(elementId)

            const fnUpdateElement = () => {
                const elementValue = this.elementManager.getElementValue(element)
                this.toggle(elementValue, definition, reverseDefinition)
            }

            fnUpdateElement()
            on(element, 'change', fnUpdateElement)
        }
    }

    toggle(elementValue, toggleDefinition, reverseDefinition) {
        toggleDefinition = constructArrayDefinition(toggleDefinition)

        // Disable fields first
        for (const [toggleValue, fields] of Object.entries(toggleDefinition)) {
            if (elementValue !== toggleValue) {
                this._toggleFields(fields, reverseDefinition)
            }
        }

        // Then enable
        if (toggleDefinition[elementValue]) {
            this._toggleFields(toggleDefinition[elementValue], !reverseDefinition)
        }
    }

    _toggleFields(fields, empty) {
        fields.forEach((field) => {
            this._setEmpty(field, empty)
        })
    }

    _setEmpty(field, empty) {
        if (empty === true) {
            const element = this.elementManager.getElement(field)
            this.elementManager.setElementValue(element, '')
        }
    }
}
