import {findAll, findOne, on} from "@/utils/dom"
import {addClass, closest, removeClass} from "@/utils/css"
import Tab from "bootstrap/js/src/tab"

export default (di, container) => {
    const forms = findAll('form', container)

    if (!forms.length) {
        return
    }

    forms.forEach((form) => {
        on(form, 'submit', () => {
            removeClass(form, 'was-validated')
        })

        findAll('input,select,textarea', form).forEach((formElement) => {
            on(formElement, 'invalid', () => {
                addClass(form, 'was-validated')
                const tabPane = closest(formElement, '.tab-pane')

                if (tabPane) {
                    const element = tabPane.hasAttribute('aria-labelledby')
                        ? findOne(`#${tabPane.getAttribute('aria-labelledby')}`, container)
                        : findOne(`a.nav-link[href="#${tabPane.id}"]`, container)
                    if(element) {
                        Tab.getOrCreateInstance(element).show()
                    }
                } else {
                    formElement.focus()
                }
            })
        })
    })
}
