import * as Sentry from '@sentry/browser'
import Container from "@/services/Container"
import registerServices from '@/services'

// Listeners
import * as addresspicker from '@/listeners/addresspicker'
import * as caseListener from '@/listeners/case'
import * as checkAll from '@/listeners/check-all'
import * as datepicker from '@/listeners/datepicker'
import * as formCollection from '@/listeners/form-collection'
import * as formCounter from '@/listeners/form-counter'
import * as formErrors from '@/listeners/form-errors'
import * as formFilter from '@/listeners/form-filter'
import * as formJsErrors from '@/listeners/form-js-errors'
import * as modalFrame from '@/listeners/modal-frame'
import * as modalTemplate from '@/listeners/modal-template'
import * as lazyListener from '@/listeners/lazy-listener'
import * as selectgroup from '@/listeners/selectgroup'
import * as selectgroupRange from '@/listeners/selectgroup-range'
import * as submitOnChange from '@/listeners/submit-on-change'
import * as switchUser from '@/listeners/switch-user'
import * as tableSort from '@/listeners/table-sort'
import * as tabUrl from '@/listeners/tab-url'
import * as toggleEmptySelectgroup from '@/listeners/toggle-empty-selectgroup'
import * as tooltip from '@/listeners/tooltip'
import * as turbo from '@/listeners/turbo'
import {domReady, findAll} from "@/utils/dom"

lazyListener.addListener({
    name: 'slider',
    elements: (container) => findAll('.slider', container),
    load: () => import('@/listeners/slider')
})

lazyListener.addListener({
    name: 'html-editor',
    elements: (container) => findAll('.html-editor', container),
    load: () => import('@/listeners/html-editor')
})

class App {
    #di = null

    #started = false

    #componentsCallbacks = {}

    #pageResolvers = {}

    #pages = {}

    #pageListeners = [
        turbo
    ]

    #listeners = [
        addresspicker,
        checkAll,
        caseListener,
        datepicker,
        formCollection,
        formCounter,
        formErrors,
        formFilter,
        formJsErrors,
        modalFrame,
        modalTemplate,
        lazyListener,
        selectgroup,
        selectgroupRange,
        submitOnChange,
        switchUser,
        tableSort,
        tabUrl,
        toggleEmptySelectgroup,
        tooltip,
    ]

    start(parameters) {
        this.#started = true
        this.#di = new Container(parameters)

        registerServices(this.#di, this)

        if (parameters.dsn) {
            Sentry.init({
                dsn: parameters.dsn,
                release: parameters.release,
                tracesSampleRate: 1.0,
                integrations: [Sentry.browserTracingIntegration()],
                environment: parameters.environment,
                autoSessionTracking: false,
                debug: this.dev,
            })

            Sentry.getCurrentScope().setUser(this.get('user'))
        }

        // Execute the page load listeners
        this.#pageListeners.forEach(({default: listener}) => {
            listener({app: this})
        })

        this.dispatchPageLoadedEvent()

        // Run delayed page load
        Object.keys(this.#pages).forEach((pageId) => {
            if (typeof this.#pageResolvers[pageId] !== 'undefined') {
                const resolvers = this.#pageResolvers[pageId]
                delete this.#pageResolvers[pageId]
                resolvers.forEach((resolver) => resolver())
            }
        })
    }

    /**
     * @param {String} pageId
     */
    isPageRegistered(pageId) {
        return typeof this.#pages[pageId] !== 'undefined'
    }

    /**
     * @param {String} pageId
     * @param {Function} initializer
     * @param {Object} options
     */
    registerPage(pageId, initializer, options = {}) {
        if (this.isPageRegistered(pageId)) {
            console.warn(`Page initializer for ${pageId} already exists.`)
            return
        }

        this.#pages[pageId] = {initializer, options}

        // Register called after page load
        if (this.#started && typeof this.#pageResolvers[pageId] !== 'undefined') {
            const resolvers = this.#pageResolvers[pageId]
            delete this.#pageResolvers[pageId]
            resolvers.forEach((resolver) => resolver())
        }
    }

    /**
     * @param {String} pageId
     * @param {Object} parameters
     */
    async loadPage(pageId, parameters = {}) {
        // Page is already registered
        if (this.#started && this.#pages[pageId]) {
            const {initializer} = this.#pages[pageId]

            const callback = await initializer(parameters)
            this.#registerCallback(callback, pageId)
            return
        }

        // Page is not yet registered, it will be resolved during registerPage
        this.#pageResolvers[pageId] ??= []
        this.#pageResolvers[pageId].push(() => this.loadPage(pageId, parameters))
    }

    /**
     * @param {HTMLElement|Document} container
     * @param {String} pageId
     * @param {Boolean} unregister
     */
    unloadPage(pageId, container, unregister = false) {
        if (typeof this.#pageResolvers[pageId] !== 'undefined') {
            delete this.#pageResolvers[pageId]
        }
        this.#unloadComponent(pageId, container, unregister)
    }

    teardownListeners(container = document) {
        this.#listeners
            .filter(({teardown}) => !!teardown)
            .forEach(({teardown}) => {
                teardown({app: this, container})
            })
    }

    get dev() {
        return this.get('environment') === 'dev'
    }

    loadUrl(url) {
        Turbo.visit(url)
    }

    handleError(error) {
        Sentry.captureException(error)
        throw error
    }

    dispatchPageLoadedEvent(container = document) {
        this.#listeners.forEach(({default: listener}) => {
            listener({app: this, container})
        })

        window.onAppReadyCallbacks.forEach((callback) => callback(this))
        window.onAppReadyCallbacks = []
    }

    get(key) {
        return this.#di.get(key)
    }

    /**
     * @param {HTMLElement|Document} container
     * @param {String} componentId
     * @param {Boolean} unregister
     */
    #unloadComponent(componentId, container, unregister = true) {
        this.#runCallback(componentId, ({unload}) => unload && unload(container), unregister)
    }

    /**
     * @param {Function} callback
     * @param {String} componentId
     */
    #registerCallback(callback, componentId) {
        if (undefined === callback) {
            return
        }

        this.#componentsCallbacks[componentId] ??= []

        const callbacks = Array.isArray(callback) ? callback : [callback]
        callbacks.forEach((cb) => {
            this.#componentsCallbacks[componentId].push(cb)
        })
    }

    /**
     * @param {String} componentId
     * @param {Function} callback
     * @param {Boolean} unregister
     */
    #runCallback(componentId, callback, unregister) {
        if (this.#componentsCallbacks[componentId]) {
            this.#componentsCallbacks[componentId].reverse().forEach(callback)

            if (unregister) {
                delete this.#componentsCallbacks[componentId]
            }
        }
    }
}

const app = new App()
window.app = app

async function start() {
    await domReady()
    app.start(window.appParams)
}

start()
