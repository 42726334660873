export default class FormFeedbackRenderer {
    render({element, type = 'error', message}) {
        const error = document.createElement('div')
        error.classList.add('d-block', 'feedback')
        if(type === 'error') {
            error.classList.add('invalid-feedback')
        }else if(type === 'info') {
            error.classList.add('text-secondary')
        }
        error.textContent = message

        this.clear(element)
        element.after(error)
    }

    clear(element) {
        while(
            element.nextElementSibling?.classList.contains('feedback')
        || element.nextElementSibling?.classList.contains('invalid-feedback')
        ) {
            element.nextElementSibling.remove()
        }
    }
}
