import {findAll} from "@/utils/dom"

/**
 * @params {HTMLElement} container
 */
export default function init({ container }) {
    const modals = [
        container.nodeName === 'turbo-frame' && container.matches('.modal.modal-frame-reload') ? container : null,
        ... findAll('.modal.modal-frame-reload', container),
    ].filter(Boolean)
    if (!modals.length) {
        return
    }

    modals.forEach((modal) => {
        modal.addEventListener('hidden.bs.modal', () => {
            const frame = modal
            const src = frame.src
            frame.setAttribute('disabled', '')
            frame.removeAttribute('src')
            frame.removeAttribute('loading')
            frame.setAttribute('src', src)
            frame.setAttribute('loading', 'lazy')
            frame.removeAttribute('disabled')
        })
    })
}
