import {findAll} from "@/utils/dom"

/**
 * @params {HTMLElement} container
 */
export default function init({ container }) {
    const modals = [
        ... findAll('.modal.modal-template', container),
    ].filter(Boolean)
    if (!modals.length) {
        return
    }

    modals.forEach((modal) => {
        const template = modal.innerHTML
        modal.addEventListener('hidden.bs.modal', () => {
            modal.innerHTML = template
        })
    })
}
