import {findAll, findOne, on} from "@/utils/dom"

/**
 * @params {HTMLElement} container
 */
export default function init({ container }) {
    const submitsOnChange = findAll('.submit-on-change', container)
    if (!submitsOnChange.length) return

    submitsOnChange.forEach((submitOnChange) => {
        on(submitOnChange, 'change', () => {
            const form = submitOnChange.dataset.formId ? findOne(`#${submitOnChange.dataset.formId}`) : submitOnChange.closest('form')
            form.requestSubmit()
        })
    })
}
