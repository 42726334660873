import { constructObjectDefinition } from '@/utils/utils'

export default class FormManager {
    /**
     * @param {VisibleManager} visibleManager
     * @param {DisabledManager} disabledManagager
     * @param {RequiredManager} requiredManager
     * @param {EmptyManager} emptyManager
     */
    constructor(visibleManager, disabledManagager, requiredManager, emptyManager) {
        this.visibleManager = visibleManager
        this.disabledManagager = disabledManagager
        this.requiredManager = requiredManager
        this.emptyManager = emptyManager
    }

    handle(formDefinition) {
        formDefinition = constructObjectDefinition(formDefinition)
        if (formDefinition.visibility) {
            this.visibleManager.handle(formDefinition.visibility, false)
        }

        if(formDefinition.visibilityReverse) {
            this.visibleManager.handleReverse(formDefinition.visibilityReverse, false)
        }

        if (formDefinition['!visibility']) {
            this.visibleManager.handle(formDefinition['!visibility'], true)
        }

        if (formDefinition.disable) {
            this.disabledManagager.handle(formDefinition.disable, false)
        }

        if (formDefinition['!disable']) {
            this.disabledManagager.handle(formDefinition['!disable'], true)
        }

        if (formDefinition.require) {
            this.requiredManager.handle(formDefinition.require, false)
        }

        if (formDefinition['!require']) {
            this.requiredManager.handle(formDefinition['!require'], true)
        }

        if (formDefinition.empty) {
            this.emptyManager.handle(formDefinition.empty, false)
        }

        if (formDefinition['!empty']) {
            this.emptyManager.handle(formDefinition['!empty'], true)
        }
    }
}
