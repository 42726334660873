import {findAll, on} from "@/utils/dom"
import {closest, toggle} from "@/utils/css"

/**
 * @params {HTMLElement} container
 */
export default async function init({ container }) {
    const toggles = findAll('.toggle-empty-selectgroup', container)

    if (!toggles.length) {
        return
    }

    toggles.forEach((toggleElement) => {
        const wrapper= closest(toggleElement, '.toggle-empty-selectgroup-wrapper')
        const targets = findAll('.toggle-empty-selectgroup-target', wrapper)
        on(toggleElement, 'change', () => {
            targets.forEach(target => toggle(target, !toggleElement.checked))
        })
    })
}
