import Swal from 'sweetalert2'
import {hide, show} from "@/utils/css"
import {dom} from "@/utils/dom"
import {LABEL_CANCEL} from "@/translator"


export default class ModalManager {
    constructor(di) {
        this._di = di
        this._swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-secondary"
            },
            cancelButtonText: di.get('translator').trans(LABEL_CANCEL),
            buttonsStyling: false
        })
    }

    createLoading() {
        const loading = dom('#navbar-loading')
        if (!loading) {
            return
        }
        show(loading)
    }

    showLoading() {
        const loading = dom('#navbar-loading')
        if (!loading) {
            return
        }
        show(loading)
    }

    hideLoading() {
        const loading = dom('#navbar-loading')
        if (!loading) {
            return
        }
        hide(loading)
    }

    hideModals() {
        Swal.close()
    }

    createError(params) {
        params = {
            icon: 'error',
            showCloseButton: true,
            showCancelButton: false,
            focusCancel: false,
            focusConfirm: true,
            heightAuto: false,
            ...params,
        }

        return this._swalWithBootstrapButtons.fire(params)
    }

    createModal(params) {
        params = {
            showCloseButton: true,
            showCancelButton: false,
            focusCancel: false,
            focusConfirm: true,
            heightAuto: false,
            ...params,
        }

        return this._swalWithBootstrapButtons.fire(params)
    }

    createConfirm(params) {
        params = {
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            focusConfirm: false,
            heightAuto: false,
            ...params,
        }

        return this._swalWithBootstrapButtons.fire(params).then((result) => result && result.value)
    }

    createPrompt(params) {
        params = {
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            focusCancel: true,
            focusConfirm: false,
            heightAuto: false,
            ...params,
        }

        return this._swalWithBootstrapButtons.fire(params).then((result) => result && result.value)
    }

    async createInput(params) {
        params = {
            input: 'text',
            showCancelButton: true,
            focusCancel: true,
            ...params,
        }

        return this._swalWithBootstrapButtons.fire(params).then((result) => result && result.value)
    }

    showValidationMessage(params) {
        return Swal.showValidationMessage(params)
    }
}
