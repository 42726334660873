import {findAll, on} from "@/utils/dom"

/**
 * @params {App} app
 */
export default async function init({ app, container }) {
    const ranges = findAll('.form-selectgroup.range', container)

    if (!ranges.length) {
        return
    }

    /** @type {FormFeedbackRenderer} */
    const formFeedbackRenderer = app.get('formFeedbackRenderer')

    ranges.forEach((range) => {
        const {rangeMin, rangeMinMessage, rangeMax, rangeMaxMessage, rangeExact, rangeExactMessage, rangeMessage} = range.dataset
        const min = '' === rangeMin ? null : parseInt(rangeMin)
        const max = '' === rangeMax ? null : parseInt(rangeMax)
        const exact = '' === rangeExact ? null : parseInt(rangeExact)
        const inputs = [... findAll('input.form-selectgroup-input', range)]

        inputs.forEach((input) => {
            on(input, 'change', updateState)
        })
        updateState()

        function lockInputs() {
            inputs
                .filter(input => !input.checked)
                .forEach(input => {
                input.disabled = true
            })
        }

        function unlockInputs() {
            inputs.forEach(input => {
                input.disabled = false
            })
        }

        function updateState() {
            const checked = inputs.filter(input => input.checked)
            const count = checked.length
            const exactChecked = exact !== null && count !== exact
            const minChecked = min !== null && count < min
            const maxChecked = max !== null && count > max
            const canAddMore = (max === null && exact === null)
                || (max !== null && count < max)
                || (exact !== null && count < exact)

            if(exactChecked) {
                formFeedbackRenderer.render({
                    element: range,
                    message: rangeExactMessage.replaceAll('{{limit}}', exact).replaceAll('{{count}}', count),
                })
            }else if(maxChecked) {
                formFeedbackRenderer.render({
                    element: range,
                    message: rangeMaxMessage.replaceAll('{{max}}', max).replaceAll('{{min}}', min),
                })
            }else if(minChecked) {
                formFeedbackRenderer.render({
                    element: range,
                    message: rangeMinMessage.replaceAll('{{max}}', max).replaceAll('{{min}}', min),
                })
            } else if(rangeMessage) {
                formFeedbackRenderer.render({
                    element: range,
                    type: 'info',
                    message: rangeMessage
                        .replaceAll('{{count}}', count)
                        .replaceAll('{{limit}}', exact ?? max)
                        .replaceAll('{{max}}', max)
                        .replaceAll('{{min}}', min),
                })
            } else {
                formFeedbackRenderer.clear(range)
            }

            if(! canAddMore) {
                lockInputs()
            } else {
                unlockInputs()
            }
        }
    })
}
