import {findOne} from "@/utils/dom"
import Tab from 'bootstrap/js/src/tab'

/**
 * @params {App} app
 * @params {HTMLElement} container
 */
export default async function init({ container }) {
    const url = document.location.toString()
    if (!url.match('#')) {
        return
    }

    const element = findOne(`a.nav-link[href="#${url.split('#')[1]}"]`, container)
    if(element) {
        Tab.getOrCreateInstance(element).show()
    }
}
