export default class ErrorRenderer {
    /**
     * @param {HttpResponse} response
     */
    renderError(response) {
        if (response.data?.html) {
            return response.data.html
        }

        const alert = document.createElement('div')
        alert.className = 'alert alert-danger'
        if (response.data?.detail) {
            alert.textContent = response.data.detail

            if (response.data.trace) {
                const card = document.createElement('div')
                card.className = 'card mb-4'
                const tableResponsive = document.createElement('div')
                tableResponsive.className = 'table-responsive'
                const table = document.createElement('table')
                table.className = 'table card-table table-bordered'
                const thead = document.createElement('thead')
                const tbody = document.createElement('tbody')
                const tr = document.createElement('tr')
                const thFile = document.createElement('th')
                thFile.textContent = 'File'
                const thLine = document.createElement('th')
                thLine.textContent = 'Line'
                const thFunction = document.createElement('th')
                thFunction.textContent = 'Function'
                tr.appendChild(thFile)
                tr.appendChild(thLine)
                tr.appendChild(thFunction)
                thead.appendChild(tr)
                table.appendChild(thead)
                table.appendChild(tbody)
                tableResponsive.appendChild(table)
                card.appendChild(tableResponsive)

                response.data.trace.forEach(trace => {
                    const tr = document.createElement('tr')
                    const tdFile = document.createElement('td')
                    const tdLine = document.createElement('td')
                    const tdFunction = document.createElement('td')

                    const fileLink = document.createElement('a')
                    fileLink.href = `phpstorm://open?file=${trace.file}&line=${trace.line}`
                    fileLink.textContent = trace.file
                    tdFile.appendChild(fileLink)

                    tdLine.textContent = trace.line
                    tdFunction.textContent = `${trace.class}${trace.type}${trace.function}`

                    tr.appendChild(tdFile)
                    tr.appendChild(tdLine)
                    tr.appendChild(tdFunction)
                    tbody.appendChild(tr)
                })

                return alert.outerHTML + card.outerHTML
            }

            return alert.outerHTML
        }

        if(response.data?.title) {
            alert.textContent = response.data.title
            return alert.outerHTML
        }

        switch(response.statusCode) {
            case 401:
                alert.textContent = 'Unauthorized'
                break
            case 403:
                alert.textContent = 'Forbidden'
                break
            case 404:
                alert.textContent = 'Not Found'
                break
            case 500:
                alert.textContent = 'Internal Server Error'
                break
            default:
                alert.textContent = response.statusText
        }

        return alert.outerHTML
    }
}
