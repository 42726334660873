import { data, findAll, trigger } from './dom'
import { hasClass } from './css'

export const mergeObjects = (... objects) => objects.reduce((merged, current) => ({ ...merged, ...current }), {})

export const constructObjectDefinition = (definitions) => {
    const theDefinitions = {}

    for (const [definitionNames, definitionValue] of Object.entries(definitions)) {
        definitionNames.split(',').forEach((definitionName) => {
            definitionName = definitionName.trim()
            theDefinitions[definitionName] = theDefinitions[definitionName] || {}
            for (const [definitionProperty, definitionPropertyDefinition] of Object.entries(definitionValue)) {
                theDefinitions[definitionName][definitionProperty] = {
                    ...(theDefinitions[definitionName][definitionProperty] || {}),
                    ...definitionPropertyDefinition,
                }
            }
        })
    }

    return theDefinitions
}

export const constructArrayDefinition = (definitions) => {
    const theDefinitions = {}

    for (const [definitionName, definitionValue] of Object.entries(definitions)) {
        definitionName.split(',').forEach((splitedDefinitionName) => {
            theDefinitions[splitedDefinitionName] = (theDefinitions[splitedDefinitionName] || []).concat(
                definitionValue
            )
        })
    }

    return theDefinitions
}

export const constructReverseDefinition = (definitions) => {
    const theDefinitions = {}

    for (const [definitionName, definitionValue] of Object.entries(definitions)) {
        definitionName.split(',').forEach((splitedDefinitionName) => {
            theDefinitions[splitedDefinitionName] = (theDefinitions[splitedDefinitionName] || []).concat(
                definitionValue
            )
        })
    }

    return theDefinitions
}

export const openInNewTab = (url) => {
    window.open(url, '_blank').focus()
}

const intersect = (...arrays) => {
    const firstArray = arrays.shift()

    const intersect = []
    const BreakException = {}
    try {
        firstArray.forEach((value, index) => {
            arrays.forEach((array) => {
                if (array[index] !== value) {
                    throw BreakException
                }
            })
            intersect.push(value)
        })
    } catch (e) {
        if (e !== BreakException) throw e
    }

    return intersect
}

const isNumeric = (str) => {
    if (typeof str !== 'string') return false // we only process strings!
    return (
        !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !Number.isNaN(parseFloat(str))
    ) // ...and ensure strings of whitespace fail
}

const setVirtualFormElement = (formElement, parent = {}) => {
    const { isCollection } = formElement

    if (formElement.keys.length === 0) {
        return parent
    }

    const currentKey = formElement.keys.shift()

    // Last child
    if (formElement.keys.length === 0) {
        if (isCollection) {
            parent[`_${currentKey}`] = formElement.id
            parent[currentKey] = parent[currentKey] || []
        } else {
            parent[currentKey] = formElement.id
        }

        return parent
    }

    const childKey = [...formElement.keys].shift()
    if (typeof parent[currentKey] === 'undefined') {
        parent[currentKey] = isNumeric(childKey) ? [] : {}
    }

    parent[currentKey] = setVirtualFormElement(formElement, parent[currentKey])

    return parent
}

export const getElementValue = (element) => {
    if (element.type === 'checkbox') {
        return element.checked
    }

    if (element.tagName === 'SELECT') {
        const isMultiple = element.hasAttribute('multiple')

        if (element.selectedIndex === -1) {
            return isMultiple ? [] : null
        }

        if (isMultiple) {
            return [...element.options]
                .filter((option) => option.selected)
                .map((option) => {
                    const value = data(option, 'value')
                    if (value !== undefined) {
                        return value
                    }

                    return option.value
                })
        }

        const selectedOption = element.options[element.selectedIndex]
        const value = data(selectedOption, 'value')
        if (value !== undefined) {
            return value
        }

        return selectedOption.value
    }

    return element.value
}

export const getVirtualForm = (container, virtualForm = {}) => {
    const formIdKeys = []
    const formElements = []
    findAll(
        '.collection, input[type="hidden"], .form-control, .custom-file-input, .custom-control-input',
        container
    ).forEach((field) => {
        if (field.id) {
            const fieldIdKeys = field.id.split('_')
            formIdKeys.push(fieldIdKeys)
            formElements.push({
                id: field.id,
                isCollection: hasClass(field, 'collection'),
                keys: fieldIdKeys,
            })
        }
    })

    const offsetsInCommon = intersect(...formIdKeys).length
    for (const formElement of formElements) {
        // When we have only one child we keep only last id as name
        if (formElements.length === 1 && offsetsInCommon > 1) {
            formElement.keys = formElement.keys.slice(-1)
        } else {
            formElement.keys = formElement.keys.slice(offsetsInCommon)
        }

        // Goal of this function: setVirtualFormElement(["costShares", "0", "foo"], "formID", virtualForm) -> virtualForm["costShares"][0]["foo"] = "formID";
        setVirtualFormElement(formElement, virtualForm)
    }

    return virtualForm
}

export const setElementValue = (element, value) => {
    if (element.type === 'checkbox') {
        element.checked = !!value
    } else if (element.tagName === 'SELECT') {
        findAll('option', element).forEach((option) => {
            option.selected = Array.isArray(value) ? value.indexOf(option.value) !== -1 : option.value === value

            const optionValue = data(option, 'value')
            if (option.selected !== true && undefined !== optionValue) {
                option.selected = Array.isArray(value) ? value.indexOf(optionValue) !== -1 : optionValue === value
            }
        })

        // Use custom refresh event to be used to refresh select view
        trigger(element, 'refresh')
    } else {
        element.value = value
    }
}
