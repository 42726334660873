import {findAll, findOne, on} from "@/utils/dom"
import {closest} from "@/utils/css"
import {classes} from "@/services/form/CollectionManager"

/**
 * @params {App} app
 * @params {HTMLElement} container
 */
export default function init({ container, app }) {
    const addCollectionBtns = findAll(`.${classes.addCollectionButton}`, container)
    const removeCollectionBtns = findAll(`.${classes.removeCollectionButton}`, container)

    if (!addCollectionBtns.length && !removeCollectionBtns.length) {
        return
    }

    const collectionManager = app.get('collectionManager')
    addCollectionBtns.forEach((btn) => {
        on(btn, 'click', (e) => {
            e.preventDefault()
            e.stopPropagation()

            const wrapper = closest(btn, `.${classes.collectionWrapper}`)
            const collection = findOne(`.${classes.collection}`, wrapper)
            collectionManager.addElement(collection)
        })
    })

    removeCollectionBtns.forEach((btn) => {
        on(btn, 'click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            collectionManager.removeElement(btn)
        })
    })
}
