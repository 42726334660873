import {findAll} from "@/utils/dom"

/**
 * @params {App} app
 * @params {HTMLElement} container
 */
export default async function init({ container, app }) {
    const datepickers = findAll('.datepicker', container)

    if (!datepickers.length) {
        return
    }

    /** @var {DateManager} dateManager */
    const dateManager = await app.get('dateManager')
    datepickers.forEach((datepicker) => {
        dateManager.handle({
            element: datepicker
        })
    })
}
