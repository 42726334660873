import {findAll} from "@/utils/dom"

/**
 * @param {App} app
 * @param {HTMLElement} container
 */
export default async function init({ container }) {
    const counters = findAll('.form-counter', container)

    if (!counters.length) {
        return
    }

    counters.forEach((counter) => {
        const counterElement = counter.nextElementSibling
        const template = counterElement.innerHTML

        counter.addEventListener('input', () => updateCounter(counter, counterElement, template))
        updateCounter(counter, counterElement, template)
    })

    function updateCounter(counter, counterElement, template) {
        const { minLength, maxLength, value } = counter

        if(value.length < minLength || value.length > maxLength) {
            counterElement.innerHTML = template.replaceAll('{counter}', `<span class="text-danger">${value.length}</span>`)
            return
        }

        counterElement.innerHTML = template.replaceAll('{counter}', `<span class="text-success">${value.length}</span>`)
    }
}
