import { getVirtualForm, setElementValue } from '@/utils/utils'
import {dom, data, appendHTML, trigger, on, findAll, remove} from "@/utils/dom"
import {closest} from "@/utils/css"

export const classes = {
    collection: 'form-collection',
    collectionItem: 'collection-item',
    collectionWrapper: 'collection-wrapper',
    addCollectionButton: 'add-collection',
    removeCollectionButton: 'remove-collection',
}

export default class CollectionManager {
    /**
     * @param {ModalManager} modalManager
     * @param {App} app
     */
    constructor(modalManager, app) {
        this.modalManager = modalManager
        this.app = app
    }

    handle(collection, _collection, itemHandler) {
        // Existing items
        for (const [index, form] of typeof collection === 'object' ? Object.entries(collection) : []) {
            if (!index.startsWith('_')) {
                itemHandler(form)
            }
        }

        // New item
        const collectionDOM = dom(`#${_collection || collection}`)
        on(collectionDOM, 'collection.add', (e) => {
            const collectionItem = e.detail.item
            itemHandler(getVirtualForm(collectionItem))
        })
    }

    addElement(collection, elementValues = {}, countCallBack = null, dispatchEvent = true) {
        const counter = countCallBack ? countCallBack(collection) : collection.children.length

        let prototypeItemHTML = data(collection, 'prototype')
        const prototypeItemName = data(collection, 'prototypeName') || '__name__'
        prototypeItemHTML = prototypeItemHTML.replace(new RegExp(prototypeItemName, 'g'), counter)

        appendHTML(collection, prototypeItemHTML)
        const collectionItem = collection.lastElementChild
        if (elementValues) {
            this._populate(collectionItem, elementValues)
        }

        if (dispatchEvent === true) {
            trigger(collection, 'collection.add', { item: collectionItem })
            this.app.dispatchPageLoadedEvent(collectionItem)
            trigger(collection, 'collection.added', { item: collectionItem })
        }
    }

    _populate(elementDOM, elementValues) {
        const virtualForm = getVirtualForm(elementDOM)
        for (const [formElementName, formElement] of Object.entries(virtualForm)) {
            // Skip _element (= collection or nested form element)
            if (formElementName.startsWith('_')) {
                continue
            }

            // Value is missing
            if (typeof elementValues[formElementName] === 'undefined') {
                continue
            }

            const value = elementValues[formElementName]

            // Collection elements
            if (Array.isArray(elementValues[formElementName])) {
                if (typeof virtualForm[`_${formElementName}`] !== 'undefined') {
                    const parent = dom(`#${virtualForm[`_${formElementName}`]}`)
                    elementValues[formElementName].forEach((childElementValues) => {
                        // Don't dispatch events for nested collection items as main item item will be dispatched
                        this.addElement(parent, childElementValues, null, false)
                    })
                } else if (Array.isArray(value)) {
                    // Select multiple
                    const element = dom(`#${formElement}`)
                    setElementValue(element, value)
                } else {
                    console.warn(`Element _${formElementName} not found in `, virtualForm)
                }
                continue
            }

            // Nested element
            if (typeof formElement === 'object') {
                if (typeof virtualForm[`_${formElementName}`] !== 'undefined') {
                    const element = dom(`#${virtualForm[`_${formElementName}`]}`)
                    this._populate(element, value)
                } else {
                    // Embedded
                    for (const [formChildElementName, formChildElement] of Object.entries(formElement)) {
                        const element = dom(`#${formChildElement}`)
                        this._populate(element.parentNode, { [formChildElementName]: value[formChildElementName] })
                    }
                }
                continue
            }

            const element = dom(`#${formElement}`)
            setElementValue(element, value && typeof value.id !== 'undefined' ? value.id : value)
        }
    }

    removeElement(btn, withConfirmation = true) {
        if (withConfirmation === false) {
            this._doRemove(btn)
            return
        }

        this.modalManager
            .createConfirm({
                text: data(btn, 'confirmMessage'),
                focusConfirm: true,
                focusCancel: false,
            })
            .then((result) => {
                if (result) {
                    this._doRemove(btn)
                }
            })
    }

    emptyCollection(collection) {
        findAll(`.${classes.removeCollectionButton}`, collection).forEach((btn) => {
            this._doRemove(btn)
        })
    }

    _doRemove(btn) {
        const collection = closest(btn, `.${classes.collection}`)
        const collectionItem = closest(btn, data(btn, 'item') || `.${classes.collectionItem}`)

        remove(collectionItem)
        trigger(collection, 'collection.deleted')
    }
}
