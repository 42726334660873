import {findAll} from "@/utils/dom"
import Tooltip from 'bootstrap/js/src/tooltip'

/**
 * @params {App} app
 */
export default async function init({ container }) {
    findAll('.tooltip').forEach((tooltip) => Tooltip.getOrCreateInstance(tooltip).hide())

    const tooltips = findAll('[data-bs-toggle="tooltip"]', container)

    if (!tooltips.length) {
        return
    }

    tooltips.forEach((tooltip) => {
        const options = {}
        if (tooltip.getAttribute('data-tooltip-enable-html')) {
            options.html = !!tooltip.getAttribute('data-tooltip-enable-html')
        }
        Tooltip.getOrCreateInstance(tooltip)
    })
}
