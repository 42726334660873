import {findAll} from "@/utils/dom"

/**
 * @params {HTMLElement} container
 */
export default async function init({ container }) {
    const capitalizes = findAll('.case-capitalize', container)

    if (!capitalizes.length) {
        return
    }

    capitalizes.forEach((capitalize) => {
        capitalize.addEventListener('input', () => {
            capitalize.value = capitalize.value.replace(
                /\w\S*/g,
                text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
            )
        })
    })
}
