import {findAll, findOne} from "@/utils/dom"
import {closest} from "@/utils/css"
import Tab from 'bootstrap/js/src/tab'

/**
 * @params {HTMLElement} container
 */
export default async function init({ container }) {
    const errors = findAll('.tab-pane .is-invalid', container)

    if (!errors.length) {
        return
    }

    const firstError = errors.item(0)
    const tabPane = closest(firstError, '.tab-pane')

    const element = tabPane.hasAttribute('aria-labelledby')
        ? findOne(`#${tabPane.getAttribute('aria-labelledby')}`, container)
        : findOne(`a.nav-link[href="#${tabPane.id}"]`, container)

    if(element) {
        Tab.getOrCreateInstance(element).show()
    }
}
