import {StreamActions} from "@hotwired/turbo"

/**
 * @param {App} app
 */
export default function init({app}) {
    StreamActions.toast = function () {
        const type = this.getAttribute("type")
        const message = this.getAttribute("message")

        /** @var {ToastManager} toastManager */
        const toastManager = app.get('toastManager')

        toastManager.createToast(type, message)
    }
}
