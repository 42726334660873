import {activateScriptElement} from "@/utils/dom"

// Custom turbo actions
import initAlert from '@/actions/alert'
import initToast from '@/actions/toast'
import initReloadForm from '@/actions/reload-form'

/**
 * @param {App} app
 */
export default async function init({ app }) {
    initAlert({ app })
    initToast({ app })
    initReloadForm({ app })

    Turbo.config.forms.confirm = async (message) => {
        /** @var {ModalManager} modalManager */
        const modalManager = app.get('modalManager')
        return await modalManager.createConfirm({
            text: message,
        })
    }

    document.addEventListener('turbo:before-fetch-request', (event) => {
        const frameId = event.detail.fetchOptions.headers['Turbo-Frame']
        if (!frameId) {
            return
        }
        const frame = document.getElementById(frameId)
        if (!frame || !frame.hasAttribute('data-turbo-follow-redirect')) {
            return
        }
        event.detail.fetchOptions.headers['Turbo-Frame-Follow-Redirect'] = 1
    })

    document.addEventListener('turbo:before-fetch-response', (event) => {
        const { fetchResponse } = event.detail
        const redirectLocation = fetchResponse.response.headers.get('Turbo-Location')
        if (!redirectLocation) {
            return
        }
        event.preventDefault()
        app.loadUrl(redirectLocation)
    })

    document.addEventListener("turbo:before-frame-render", (event) => {
        const {render} = event.detail
        event.detail.render = (currentElement, newElement) => {
            // Merge js into the head
            newElement.querySelectorAll('script[src]').forEach((element) => {
                const alreadyLoaded = [... document.head.querySelectorAll('script[src]')].some((script) => script.src === element.src)
                if(alreadyLoaded) {
                    element.remove()
                } else {
                    document.head.appendChild(element)
                    element.replaceWith(activateScriptElement(element))
                }
            })

            // Merge css into the head
            newElement.querySelectorAll('link[rel="stylesheet"]').forEach((element) => {
                const alreadyLoaded = [... document.head.querySelectorAll('link[rel="stylesheet"]')].some((link) => link.href === element.href)
                if(alreadyLoaded) {
                    element.remove()
                } else {
                    document.head.appendChild(element)
                }
            })
            render(currentElement, newElement)
        }
    })

    document.addEventListener('turbo:frame-missing', async ({detail: { response, visit }}) => {
        const {status: statusCode, redirected} = response
        if (statusCode >= 500 && statusCode < 600) {
            const responseHTML = await response.text()
            const visitOptions = {
                action: 'replace',
                shouldCacheSnapshot: false,
                response: { statusCode, responseHTML, redirected }
            }
            visit(response.url,visitOptions)
        }
    })

        document.addEventListener('turbo:frame-render', ({ target }) => {
        if (
            !document.documentElement.hasAttribute('data-turbo-preview') &&
            document.documentElement.getAttribute('data-turbo-visit-direction') !== 'none'
        ) {
            app.dispatchPageLoadedEvent(target)
        }
    })

    document.addEventListener('turbo:render', ({target}) => {
        if (
            !document.documentElement.hasAttribute('data-turbo-preview') &&
            document.documentElement.getAttribute('data-turbo-visit-direction') !== 'none'
        ) {
            app.dispatchPageLoadedEvent(target)
        }
    })

    document.addEventListener('turbo:load', () => {
        if (
        !document.documentElement.hasAttribute('data-turbo-preview') &&
        document.documentElement.getAttribute('data-turbo-visit-direction') !== 'none'
    ) {
        // eslint-disable-next-line no-undef
        _wsq.push(['_setNom', 'lagence'], ['_wysistat'])
    }
    })
}
